// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-400-js": () => import("./../../../src/pages/400.js" /* webpackChunkName: "component---src-pages-400-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-become-partner-index-js": () => import("./../../../src/pages/become-partner/index.js" /* webpackChunkName: "component---src-pages-become-partner-index-js" */),
  "component---src-pages-become-partner-success-js": () => import("./../../../src/pages/become-partner/success.js" /* webpackChunkName: "component---src-pages-become-partner-success-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-documents-compare-js": () => import("./../../../src/pages/documents/compare.js" /* webpackChunkName: "component---src-pages-documents-compare-js" */),
  "component---src-pages-fax-activate-js": () => import("./../../../src/pages/fax/activate.js" /* webpackChunkName: "component---src-pages-fax-activate-js" */),
  "component---src-pages-fax-compare-js": () => import("./../../../src/pages/fax/compare.js" /* webpackChunkName: "component---src-pages-fax-compare-js" */),
  "component---src-pages-fax-success-js": () => import("./../../../src/pages/fax/success.js" /* webpackChunkName: "component---src-pages-fax-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowledge-js": () => import("./../../../src/pages/knowledge.js" /* webpackChunkName: "component---src-pages-knowledge-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-signature-compare-js": () => import("./../../../src/pages/signature/compare.js" /* webpackChunkName: "component---src-pages-signature-compare-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-support-success-js": () => import("./../../../src/pages/support/success.js" /* webpackChunkName: "component---src-pages-support-success-js" */),
  "component---src-templates-app-full-features-template-js": () => import("./../../../src/templates/AppFullFeaturesTemplate.js" /* webpackChunkName: "component---src-templates-app-full-features-template-js" */),
  "component---src-templates-app-js": () => import("./../../../src/templates/App.js" /* webpackChunkName: "component---src-templates-app-js" */)
}

